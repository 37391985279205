import { Component, HostListener, NgModule, OnInit, ViewChild } from "@angular/core";
import { NavController, ActionSheetController, ToastController, ModalController, LoadingController, IonBackButtonDelegate, AlertController, PopoverController } from '@ionic/angular';
import { NavParams } from '@ionic/angular';
//import {TripService} from "../../services/trip-service";

import { ModifierService } from "../../services/modifier-service";
import { CustomModifier, CustomModifierService } from "../../services/custommodifier-service";
import { LocationService } from "../../services/location.service";
import { PropertyService } from "../../services/property.service";

import { PictureLaodPage } from "../pictureload/pictureload";
import { HomePage } from "../home/home";

import _ from "lodash";
import { PropertyDetailsModal } from "../property-details-modal/property-details-modal.component";
import { ExtendedPropertyDetailsModal } from "../extended-property-details-modal/extended-property-details-modal";
import { FieldLabelConstants } from "../../util/FieldLabelConstants";

import { ActivatedRoute } from '@angular/router';
import { ObjectId } from '@tybys/oid';

import * as $ from "jquery";
import { AuthService } from "src/app/services/auth.service";
import { IProperty } from "src/app/interfaces/property.interface";
import { formatNumber, objectifyQueryParams } from "src/app/util/util";
import { IUser } from "src/app/interfaces/user.interface";
import { UserAdminService } from "src/app/services/user.service";
import { CompsModal } from "../comps-modal/comps-modal";
import { SharePropertyPopoverComponent } from "src/app/components/share-property-popover/share-property-popover.component";
import { AddValuModal } from "src/app/components/add-valu-modal/add-valu-modal.component";
import { ShareValuModal } from "src/app/components/share-valu-modal/share-valu-modal.component";
import { TITLES } from "src/app/util/constants";
import { mapCompsToGrid } from "../comps-modal/compsCols";

// properties are added to the property db when they are "Added to property" on search page (to be changed)
// when the property is claimed it adds an empty state eccoval object to the eccovals array, with the users ID
// we load the property by property id, find the users valuation data by filtering through the eccovals for the users id
// the modifiers array is empty on this eccoval
// 
// eccovals have few relevant fields: cmaprice, adjustment values, min/max price, and the modifiers array
// modifiers come from 2 places: there's the standard modifiers for all properties, that are set by default, and there are the custom modifiers for a given property.
// 

@Component({
  selector: 'page-property-detail',
  templateUrl: 'property-detail.html',
  styleUrls: ['./property-detail.scss'],

})
export class PropertyDetailPage {
  //functions
  public Math = Math;
  //gui controls:
  actionSheet: any;
  customfactorbuttons: any = [];

  public property: any = this.propertyService.defaultProperty();
  public completedOnInit = false; // flag to indicate load completion since property is non-null to start
  public factors: any = [];
  public factortypes: any = [];
  public selectedfactortype: any = { empty: true, id: -1 };
  public customfactors: any = [];
  public newcustomfactor: any = {};
  public pagestate = {
    "addcustomfactor": false
  };
  // number of adult
  public adults = 2;
  // number of children
  public children = 0;
  public priceadjustment: number = 100;

  public ogValuTop;
  public ogValuDOMTop;

  // public customModSvc: CustomModifierService;

  public features: any = [];

  // private modifierSvc: ModifierService;
  private dummydays = Math.random();

  public location: any;

  public address: any;

  public geocodes: any[];

  public modifierlookup: any;

  private daysonmarketcache = {};

  private fieldLabels: any = (new FieldLabelConstants()).fieldLables;
  private fieldLabelKeys: any = Object.keys(this.fieldLabels);

  private eccovalIdx = 0; //default to empty index, should change quickly? might be a prob

  // added this to protect the input box after cma input
  private isInputtingCma = false;

  private viewingAs: IUser;

  private popover: HTMLIonPopoverElement
  
  private guestLink: string // null if auth'd user

  @ViewChild(IonBackButtonDelegate, {}) backButton: IonBackButtonDelegate;

  private viewGuestValuation = false

  // nice layout address
  get labelAddress() {
    const autocompleteAddress = _.get(this.property, 'search_formatted_address')
    const manualCreateAddress = _.get(this.property, 'formatted_address')
    if (!autocompleteAddress && !manualCreateAddress) {
      return null
    }
    return autocompleteAddress ? autocompleteAddress.replace(', USA', '') : manualCreateAddress
  }
  get VALU() {
    return formatNumber(_.get(this.property, `eccovals.${this.eccovalIdx}.priceadjustment`))
  }
  get beds() {
    return formatNumber(_.get(this.property, 'BedroomsTotal'), 'N/A')
  }
  get baths() {
    return formatNumber(_.get(this.property, 'BathroomsTotalInteger'), 'N/A')
  }
  get livingArea() {
    return formatNumber(_.get(this.property, 'LivingArea'), 'N/A')
  }
  get yearBuilt() {
    return _.get(this.property, 'YearBuilt', 'N/A')
  }

  constructor(
    public nav: NavController,
    private activatedRoute: ActivatedRoute,
    public actionSheetCtrl: ActionSheetController,
    //public tripService: TripService,
    public modifierService: ModifierService,
    public custommodService: CustomModifierService,
    public locationService: LocationService,
    public propertyService: PropertyService,
    public modalController: ModalController,
    public toastCtrl: ToastController,
    public authService: AuthService,
    private alertController: AlertController,
    public loadingController: LoadingController,
    public userService: UserAdminService,
    public popoverController: PopoverController,
  ) {

    //Property Setup.
    // this.loadData(this.activatedRoute.snapshot.paramMap.get('id'));
    // this.modifierSvc = modifierService;
    // this.customModSvc = custommodService;
  }

  private async showToast(message, overrides = {}) {
		const toast = await this.toastCtrl.create({ message, duration: 3000, position: 'top', ...overrides })
		toast.present()
	}

  async ngOnInit(): Promise<void> {
    // there are 2 ways to get to this page: id (logged in user) or link (guest)
    const id = this.activatedRoute.snapshot.paramMap.get('id')
    const link = this.activatedRoute.snapshot.paramMap.get('link')

    // first, decide if the share icon should be visible
    if (link) {
      $('#shareicon').css('display', 'none')
    } else {
      document.getElementById('shareicon').addEventListener('click', async (ev) => {
        this.popover = await this.popoverController.create({
          component: SharePropertyPopoverComponent,
          cssClass: 'share-popover',
          componentProps: { openShareModal: this.openShareModal, generatePdf: this.generatePdf, isGuest: !!this.guestLink, alreadyShared: this.property.eccovals.length > 1 },
          event: ev
        })
        await this.popover.present()
      })
    }

    if (id && !link) {
      document.title = TITLES.PROPERTY_DETAILS
      await this.loadData(id)
    } else if (link) {
      document.title = TITLES.PROPERTY_DETAILS_GUEST
      this.guestLink = link
      await this.loadGuestData(id, link)
    }
    this.completedOnInit = true
  }

  openShareModal = async () => {
    this.popover.dismiss()
    const modal = await this.modalController.create({
      component: ShareValuModal,
      componentProps: { property: this.property },
      cssClass: 'share-valu-modal',
      // this is because i need the property udpated everytime :(
      // backdropDismiss: false
    })
    await modal.present() 
    modal.onDidDismiss().then(async ({ data }) => {
      if (data.update) {
        await this.loadData(this.property._id)
      }
    })
  }

  private loadData(propertyId) {
    return this.loadingController.create({})
      .then((loadingCtl) => {
        loadingCtl.present()
        return this.propertyService.findById(propertyId).toPromise()
          .then(async (property: any) => {
            //something went wrong. The alert/exception handler will do the display.
            if (!property) {
              property = this.propertyService.defaultProperty()
            }
            console.log(property)

            // originally, when only one owner could claim property this file used this.property.eccovals[this.eccovalIdx]
            // to save the trouble of updating 31 instances of this being updated, ill store the index and update that
            const params = objectifyQueryParams()
            const me = this.authService.getUser();
            let idx = property.eccovals.findIndex(e => e.created_by === me._id)
            if (params.userid && me.roles.includes('admin')) {
              this.viewingAs = await this.userService.findById(params.userid).toPromise();
              console.log("Admin view on behalf of user ", this.viewingAs)
              idx = property.eccovals.findIndex(e => e.created_by == params.userid)
            }
            this.eccovalIdx = idx;

            this.modifierlookup = _.keyBy(property.eccovals[this.eccovalIdx].modifiers, "_id")
            this.property = _.defaults(property, this.propertyService.defaultProperty());

            //console.log(this.property);
            if (!property.eccovals[this.eccovalIdx].priceadjustment) {
              property.eccovals[this.eccovalIdx].priceadjustment = property.eccovals[this.eccovalIdx].cmaprice;
            }


            if (!property.eccovals[this.eccovalIdx].daysonmarket) {
              this.getDaysonMarket(property);
            }

            // note: we're removing some of the features that seem a little funky
            const DESIRED_FEATURES = ['home_square_footage', 'year_built', 'property_type', 'lot_size_acres']
            this.features = Object.keys(this.property.features).filter(f => DESIRED_FEATURES.includes(f));
            // console.log("THE FEATURES --- ", this.features)
            this.features.sort();

            const customMods = this.property.eccovals[this.eccovalIdx].modifiers.filter(mod => mod.type === 'Custom');

            await this.modifierService.getAll().toPromise().then(modifiers => {
              this.factors = modifiers || [];
              //this.customfactors = custommodService.getAll();

              this.factors = this.factors.concat(customMods);

              this.factors = this.factors.map(element => {
                return this.resetfactors(element);
              });
              //call after the factor setups.

              this.calcAdjustedPrice(property);
              this.calcMinPrice(property);
              this.calcMaxPrice(property);

            });

            await this.modifierService.getAllType().toPromise().then(modifiertypes => {
              this.factortypes = modifiertypes || [];
              console.log("FACTOR TYPES: ", this.factortypes)
              if (this.factortypes.length) {
                // NOTE: we're not calling this on save anymore
                // only toggle if you're the first load
                // if (this.selectedfactortype.id === -1) {
                //   this.selectedfactortype = this.factortypes[0];
                // }
                this.selectedfactortype = this.factortypes[0];

                const customType = modifiertypes.find(mod => {
                  return mod.custom;
                });
                if (customType) {
                  customType.count = customMods.length;
                }
              }
            });

            loadingCtl.dismiss()
          });
      })
  }

  /**
   * Simplified property fetch, using the link index.
   * We already know the property exists, has all required modifiers, etc.
   * We just need to load the data.
   * @param link 
   */
  async loadGuestData(id, link) {
    try {
      const isRealtor = !!this.authService.getUser()
      const { property, valuidx } = await this.propertyService.findByLink(id, link, isRealtor)
      this.property = property
      this.eccovalIdx = valuidx

      const DESIRED_FEATURES = ['home_square_footage', 'year_built', 'property_type', 'lot_size_acres']
      this.features = Object.keys(this.property.features).filter(f => DESIRED_FEATURES.includes(f));
      this.features.sort();

      this.factortypes = await this.modifierService.getAllType().toPromise()
      this.selectedfactortype = this.factortypes[0]
      this.factors = this.property.eccovals[this.eccovalIdx].modifiers
      this.calcAdjustedPrice(this.property) // this displays the cma / sqft, factors must be set

      await this.showToast('Successfully loaded property')
    } catch (e) {
      console.error('Error loading guest data: ', e)
    }
  }

  ionViewDidEnter() {
    console.log('ionViewDidEnter');
    const query = objectifyQueryParams()
    if (query.tmp) {
      this.setUIBackButtonAction();
    }
  }

  setUIBackButtonAction() {
    this.backButton.onClick = async () => {
      // handle custom action here
      const alert = await this.alertController.create({
        header: 'Would you like to add this to your properties?',
        cssClass: 'custom-alert',
        buttons: [
          {
            text: 'No',
            cssClass: 'alert-button-cancel',
            handler: async () => {
              // this.nav.back();
              console.log("-- IM GOING TO DELETE")
              await this.delete()
              this.nav.back()
            }
          },
          {
            text: 'Yes',
            cssClass: 'alert-button-confirm',
            handler: () => {
              this.nav.back()
            }
          },
        ],
      });

      await alert.present()

      // if (confirm("Would you like to add this to your properties?")) {
      //   const loader = await this.loadingController.create()
      //   loader.present()
      //   await this.propertyService.removeUserFromProperty(this.property)
      //   loader.dismiss()
      // } else {
      //   console.log("Adding to the property")
      // }
      // this.nav.back()
    };
  }

  /**
   * When either cma input is updated, the property needs to be autosaved
   */
  public async cmaInpOnBlur() {
    // we just want to save when either is updated
    if (this.property.eccovals[this.eccovalIdx].cmaprice || this.property.eccovals[this.eccovalIdx].cmapricePerSqFt) {
      await this.save()
    }
  }

  public async avgDomInpOnBlur() {
    /**
     * @todo learn to handle this only if the value has changed
     */
    if (!this.property.eccovals[this.eccovalIdx].overrideDOM) {
      console.log('overriding the DOM')
      this.property.eccovals[this.eccovalIdx].overrideDOM = true
    }
    await this.save()
  }

  public addCustomFactors() {
    let factorbuttons: any = [];

    this.factors.filter(element => {
      return !element.show;
    }).forEach(element => {
      factorbuttons.push(
        {
          text: element.name,
          handler: () => {
            this.addCustomFactor(element);
          }
        }
      );
    });
    factorbuttons.push(
      {
        text: 'Cancel',
        role: 'cancel',
        /*            handler: () => {
                    this.cancel();
                }
        */
      }
    );
    this.presentActionSheet(factorbuttons);
  }

  public presentActionSheet(actionbuttons) {
    if (this.actionSheet) {
      this.actionSheet.dismiss();
    }
    this.actionSheet = this.actionSheetCtrl.create({
      header: 'Select an Address',
      buttons: actionbuttons
    });
    this.actionSheet.present();

  }

  addCustomFactor(factor) {
    factor.show = true;
  }

  async newcustomfactorsaved(modifier) {

    // s1v sould be negative. 
    modifier.s1v = Math.abs(modifier.s1v) * -1;

    const custMod = new CustomModifier(modifier);
    custMod.show = true;

    const customType = this.factortypes.find(mod => {
      return mod.custom;
    });
    if (customType) {
      customType.count = customType.count + 1;
    }


    this.factors.push(custMod);

    // on create, we want to nav back to the custom modifiers so you can see it
    await this.save();
    this.selectedfactortype = this.factortypes[3];
    this.calcAdjustedPrice(this.property) // hit an extra one to force the value to load

    return custMod;
  }

  clearnewcustomfactor() { }

  calcAdjustmentFactor(factor) {
    factor.adjustment = factor['s' + Math.abs(factor.value) + 'v'] / 100;
    factor.subdesc = this.rangeValues[factor.value - 1 || 0];
    return factor;
  }

  /*
  values for 1.  Significantly below average 2.  Below average 3.  Slightly below average 4. Average 5. Slightly above average 6. Above average 7.  Significantly above average
   */
  private readonly rangeValues: string[] = [
    'Significantly below average',
    'Below average',
    'Slightly below average',
    'Average',
    'Slightly above average',
    'Above average',
    'Significantly above average'
  ];

  getFactorValue(factor, adjustment) {
    var range = Math.abs(factor.s1v) + Math.abs(factor.s7v);
    var wholeadj = ((adjustment * 100) + Math.abs(factor.s1v)) / range;
    //factor.value = 6 * wholeadj;
    //factor.subdesc = this.rangeValues[factor.value];

    return factor['s' + factor.value + 'v'];
  }

  getFiltered() {
    if (!this.selectedfactortype || this.selectedfactortype == '') {
      this.selectedfactortype = { id: 2, name: "Interior", custom: false };
    }

    let ret = [];
    ret = (!this.factors ? [] : this.factors.filter(f => {
      // ORIGINAL: Filtered the modifiers for in-state filters
      // let res = false;

      // if (f.zip == ' ') f.zip = '';
      // if (f.city == ' ') f.city = '';
      // if (f.state == ' ') f.state = '';

      // // does this one match on 'just' the city
      // if ((f.city == this.property.City || f.city == this.property.CountyOrParish) && !f.state && !f.zip) {
      //   res = true;
      // }

      // // does this one match on 'just' the state
      // if (f.state == this.property.StateOrProvince && !f.zip) {
      //   res = true;
      // }

      // // does this one match on zip
      // if (f.zip && Number(f.zip) == this.property.PostalCode) {
      //   res = true;
      // }

      // if (f.type === 'Custom') {
      //   res = true;
      // }

      // return res;
      return true
    }));

    ret = ret.filter(f => f.type == this.selectedfactortype.name);
    // note - this function gets called a ton
    // console.log('-- GETFILTERED = ', ret)
    // console.log('-- selectedfactortype = ', this.selectedfactortype)

    return ret;
  }

  private first_pass: boolean = true;
  async calcAdjustedPrice(property, adjustSqFt = false) {
    // get the value that was selected
    console.log("Calculating Adjusted price.\n", { property, adjustSqFt })
    var listprice: number = parseFloat(property.eccovals[this.eccovalIdx].cmaprice);
    if (adjustSqFt) {
      var listpriceSqFt = parseFloat(property.eccovals[this.eccovalIdx].cmapricePerSqFt)
      listprice = listpriceSqFt * parseInt(property.LivingArea)
    }
    console.log("--- listprice = ", listprice)
    // var ret: number = parseFloat(property.eccovals[this.eccovalIdx].cmaprice);;
    var ret = listprice

    this.factors.forEach(element => {
      // ret = ret + (listprice * (element.adjustment));

      // new - but if newly added custom modifier it starts with no adjustment
      const adjustment = element.adjustment ? element.adjustment : 0;
      ret = ret + (listprice * adjustment);
      // console.log("--- ELEMENT & adjustment & ret: ", element, adjustment, ret)
    });

    property.eccovals[this.eccovalIdx].priceadjustment = ret;
    // calculate the updates for either value
    if (adjustSqFt) {
      property.eccovals[this.eccovalIdx].cmaprice = listprice
    } else {
      property.eccovals[this.eccovalIdx].cmapricePerSqFt = Math.round(listprice / parseInt(property.LivingArea))
    }

    //daysonmarket
    // this.getDaysonMarket(property);

    if (!this.first_pass) {
      $('#cma-div').addClass('highlighted');
      $('#cma-sq-div').addClass('highlighted');

      setTimeout(() => {
        $('#cma-div').removeClass('highlighted');
        $('#cma-sq-div').removeClass('highlighted');
      }, 1000);
    } else {
      this.first_pass = false;
    }

    // note: autosaving here and not in calcAdjustmentFactor bc they happen one after the other
    // console.log("saving...")
    // await this.save();
    // console.log("saved")

    //return ret;
    return Math.ceil(ret);
  }

  //caching here and in the workflow since it is a bit heavy to call /KLB
  getDaysonMarket(property) {
    /*if (property.empty) {
      property.eccovals[this.eccovalIdx].daysonmarket = 0;
      return property.eccovals[this.eccovalIdx].daysonmarket;
    }
    if (this.daysonmarketcache && this.daysonmarketcache[property.eccovals[this.eccovalIdx].priceadjustment]) {
      property.eccovals[this.eccovalIdx].daysonmarket = this.daysonmarketcache[property.eccovals[this.eccovalIdx].priceadjustment];;
      return property.eccovals[this.eccovalIdx].daysonmarket;
    }

    this.daysonmarketcache[property.eccovals[this.eccovalIdx].priceadjustment] = 0;
    this.propertyService.getDaysonMarket(property).subscribe(
      (value: any) => {
        if (value.length > 0) {
          property.eccovals[this.eccovalIdx].daysonmarket = Math.round(value[0].dom ? value[0].dom : value[0]);
        } else {
          property.eccovals[this.eccovalIdx].daysonmarket = 24;
        }
        this.daysonmarketcache[property.eccovals[this.eccovalIdx].priceadjustment] = property.eccovals[this.eccovalIdx].daysonmarket;
      });*/
  }

  calcMinPrice(property) {
    var listprice: number = parseFloat(property.eccovals[this.eccovalIdx].cmaprice);
    var ret: number = listprice;

    this.factors.forEach(element => {
      if (element.show) {
        ret = ret + (listprice * (element.s1v / 100));
      }
    });

    property.eccovals[this.eccovalIdx].minprice = Math.floor(ret);

    return Math.floor(property.eccovals[this.eccovalIdx].minprice);
  }

  calcMaxPrice(property) {
    var listprice: number = parseFloat(property.eccovals[this.eccovalIdx].cmaprice);
    var ret: number = listprice;
    this.factors.forEach(element => {
      if (element.show) {
        ret = ret + (listprice * (element.s7v / 100));
      }
    });

    property.eccovals[this.eccovalIdx].maxprice = Math.ceil(ret);

    return Math.ceil(property.eccovals[this.eccovalIdx].maxprice);
  }

  isCustomFactor(factor) {
    let seltype = this.factortypes.filter(ftype => {
      return factor.type === ftype.name;
    });
    return (seltype && seltype.length && seltype[0].custom);
  }

  resetfactors(factor) {

    factor.show = true;

    if (factor.show) {
      this.modifierlookup = _.keyBy(this.property.eccovals[this.eccovalIdx].modifiers, "_id")

      const defaultvalue = (this.modifierlookup[this.getId(factor)] && this.modifierlookup[this.getId(factor)].value)

      if (defaultvalue == null) {
        // if a value is not set on the incomming data then set this to the middle value.
        factor.value = 4;
      } else {
        factor.value = defaultvalue; //this.getFactorValue(factor, defaultvalue);
      }

      this.calcAdjustmentFactor(factor);
      return factor;
    }

  }

  getId(factor) {
    return factor._id.toString();
  }

  formatfeature(feature) {
    return _.startCase(feature);
  }

  // view trip detail
  captureImage(id) {
    // this.nav.push(PictureLaodPage, { sourcePage: this });
    this.nav.navigateForward('picture', { state: { sourceProperty: this.property } });
  }

  reorderImages(id) {
    this.nav.navigateForward('reorder-property-images', { state: { sourceProperty: this.property } })
  }

  saveButton() {
    return this.save();
  }

  async save(displayToast = true, displayLoader = true) {
    this.property.eccovals[this.eccovalIdx].modifiers = this.factors.filter(element => element.show);

    const loader = await this.loadingController.create({})
    displayLoader && loader.present()

    // update the property so the current screen, with updated version number, is what we're updating.
    // this is a create or update call, and if the guestLink is provided it will use the guest update endpoint
    this.property = await this.propertyService.save(this.property, this.guestLink).toPromise()

    // once the comps are set properly, we can run the DOM algorithm and push the update
    try {
      const { avgDOM, valuDOM } = await this.propertyService.getDOM(this.property._id, this.guestLink) as any
      this.property.eccovals[this.eccovalIdx].avgDOM = avgDOM
      this.property.eccovals[this.eccovalIdx].valuDOM = valuDOM
      this.property = await this.propertyService.save(this.property, this.guestLink, !!(this.authService.getUser())).toPromise()
    } catch (e) {
      console.warn(`Error saving DOM for property: `, e)
    }

    if (displayToast) {
      await this.toastCtrl.create({ message: 'Property Saved Successfully!', duration: 3000, position: 'top' }).then(ctl => ctl.present())
    }

    displayLoader && loader.dismiss();

    // await this.loadData(this.activatedRoute.snapshot.paramMap.get('id'));
    const valu = this.calcAdjustedPrice(this.property)
    // console.log("--- VALUE IS!!: ", valu)
  }

  normalizeDisplayValue(value: string): string {
    if (value == 'False') return 'No';
    if (value == 'True') return 'Yes';

    return value;
  }

  viewExtendedPropertyDetails() {
    this.modalController.create({
      component: ExtendedPropertyDetailsModal,
      componentProps: { property: this.property },
      cssClass: 'epd-modal'
    }).then((ctl) => {

      ctl.onDidDismiss().then(data => {
        // reload the property from the db
        // this.property = data.dismissed;
        this.propertyService.findById(this.activatedRoute.snapshot.paramMap.get('id'));
      });
      ctl.present();
    });

  }

  async checkDelete() {
    const alert = await this.alertController.create({
      header: 'Are you sure you would like to delete this property?',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: 'No',
          cssClass: 'alert-button-cancel',
          handler: async () => {
            // this.nav.back();

          }
        },
        {
          text: 'Yes',
          cssClass: 'alert-button-confirm',
          handler: async () => {
            console.log("-- HELLO! I should be trying to delete!!")
            await this.delete()
            this.nav.navigateRoot('home')
          }
        },
      ],
    })

    await alert.present();
  }

  // remove the property from this user
  async delete() {
    // if (confirm("Sure you want to delete this property?")) {
    //   console.log("this.property ", this.property)
    //   await this.propertyService.removeUserFromProperty(this.property).toPromise()
    //   this.nav.navigateRoot('home');
    // }
    const loader = await this.loadingController.create()
    loader.present()
    console.log("-- and im REMOVING YOUR USER!!!!!!")
    await this.propertyService.removeUserFromProperty(this.property).toPromise()
    console.log(".... and im successful!!")
    loader.dismiss()
  }

  onScroll($event) {
    const header = $('#header')
    const valu = $('#valu')
    const valuDOM = $('#valuDOM')
    const guestLinkAnalytics = $('.guestLink-options-ctr')

    if (!this.ogValuTop) {
      this.ogValuTop = valu.position().top;
    }

    if (valu.css('position') !== 'fixed') {
      if (valu.offset().top < header.height()) {
        // this is actually the anchor point
        this.ogValuTop = $event.detail.scrollTop
        valu.css({
          position: 'fixed',
          top: header.height(),
          zIndex: 100,
          marginTop: 0,
          width: valu.width()
        })
      }
    } else {
      if ($event.detail.scrollTop <= (this.ogValuTop - 20)) {
        valu.css('position', 'relative')
        valu.css('top', '0px')
        guestLinkAnalytics.css('padding-top', 36) // weird hardcode value that puts the section back
      }
    }

    const DOM_OFFSET = 49
    if (!this.ogValuDOMTop) {
      this.ogValuDOMTop = valuDOM.position().top;
    }

    if (valuDOM.css('position') !== 'fixed') {
      if (valuDOM.offset().top < header.height() + DOM_OFFSET) {
        // this is actually the anchor point
        this.ogValuDOMTop = $event.detail.scrollTop
        valuDOM.css({
          position: 'fixed',
          top: header.height() + DOM_OFFSET,
          zIndex: 100,
          marginTop: 0,
          width: valuDOM.width()
        })
      }
    } else {
      if ($event.detail.scrollTop <= (this.ogValuDOMTop - 20)) {
      // if ($event.detail.scrollTop <= (this.ogValuDOMTop + 10)) {
        valuDOM.css('position', 'relative')
        // something weird is going on here.. for mobiles I'll just make it tighter
        if (window.innerWidth <= 600) {
          valuDOM.css('top', '25px')
        } else {
          valuDOM.css('top', '15px')
        }
      }
    }
  }

  async autofillCmaOG() {
    const loader = await this.loadingController.create({})
    try {
      loader.present()
      const { property, cmaPerSqFt } = await this.propertyService.autofillCma(this.property._id).toPromise()
      // this.calcAdjustedPrice(this.property)
      console.log("-- PROPERTY: ", property)
      console.log("-- CMA / SQ FT: ", cmaPerSqFt)
      console.log("-- successful response from autofill cma: ", this.property)
      this.property = property
      this.property.eccovals[this.eccovalIdx].cmapricePerSqFt = cmaPerSqFt
      this.calcAdjustedPrice(property, true)
      await this.save()
    } catch (e) {
      console.log("--- EEROR AUTOFILLING CMA: ", e)
    }
    loader.dismiss()
  }

  async autofillCmaOG2() {
    const loader = await this.loadingController.create({})
    loader.present()

    const zpid = await this.propertyService.searchByAddress(this.property.search_formatted_address.replace(', USA', ''))
    const zprop = await this.propertyService.getZillowProperty(zpid)
    const comps = await this.propertyService.getComps(zpid, zprop)
    console.log("zpid", zpid)
    console.log("zprop", zprop)
    console.log("comps", comps)

    const finalComps = await this.propertyService.calculateCma(comps)
    console.log("finalComps = ", finalComps)
    this.property.zillowComps = comps
    this.property.zillowFinalComps = {
      finalComps: finalComps.finalComps,
      cmaPerSquareFt: finalComps.cmaPerSqFt,
      // created_on: '',
      // created_by: ''
    }

    this.property.eccovals[this.eccovalIdx].cmapricePerSqFt = finalComps.cmaPerSqFt
    this.calcAdjustedPrice(this.property, true)
    await this.save()

    loader.dismiss()
  }

  async autofillCma() {
    const loader = await this.loadingController.create({})
    loader.present()

    // NOTE: temporary solution: if we find no comps we do an automatic single retry
    // for properties within a hardcoded radius. In the future, we'll be able to expand this
    // radius dynamically - perhaps include more parameters for the user to toggle to find the closest
    // property they can that serves as an adequate comp.
    let comps, finalComps, cmaPerSquareFt
    const radiusAttempts = [1, 3]
    for (let i = 0; i < 2; i++) {
      const radius = radiusAttempts[i]
      console.log("PropertyDetail:Attempting Comps at radius " + radius)
      comps = await this.propertyService.realtyComps(this.property, radius)
      finalComps = this.propertyService.filterComps(comps)
      cmaPerSquareFt = Math.round(finalComps.reduce((acc, curr) => acc + curr.pricePerSqFoot, 0) / finalComps.length)
      console.log(".... COMPS ARE: ", comps)
      console.log(".... FINAL COMPS ARE: ", finalComps)
      console.log("CMA PER SQ FT: ", cmaPerSquareFt)

      if (comps.length) {
        console.log(`PropertyDetail:Comps found for radius ${radius}`)
        break;
      } else {
        console.log(`PropertyDetail:NO COMPS FOUND for iteration ${i}:Retrying`)
      }
    }

    // if no comps exist we'll just stop here
    if (!comps.length) {
      loader.dismiss()
      const toast = await this.toastCtrl.create({
        message: 'Property has no valid comparables at this time. Please input Avg $ / Sq Ft from your trusted CMA source.',
        duration: 5000,
        position: 'top'
      })
      return toast.present()
    }

    // NOTE: this is slightly fake news... but we'll just fit the system for now
    // we stopped using zillow api for the realty in us API (that pulls from realtor)
    this.property.zillowComps = comps
    this.property.zillowFinalComps = {
      finalComps,
      cmaPerSqFt: cmaPerSquareFt
    }

    console.log("--- BEFORE SAVING: ", this.property.zillowComps, this.property.zillowFinalComps)

    // if (cmaPerSquareFt) {
    //   this.property.eccovals[this.eccovalIdx].cmapricePerSqFt = cmaPerSquareFt
    //   this.calcAdjustedPrice(this.property, true)
    //   await this.save()
    // } else {
    //   console.log("NO CMA PER SQ FOOT WITH COMPS")

    // NOTE: we have to do this after so the properties have the correct comps...
    // const { avgDOM, valuDOM } = await this.propertyService.getDOM(this.property._id) as any
    // this.property.eccovals[this.eccovalIdx].avgDOM = avgDOM
    // this.property.eccovals[this.eccovalIdx].valuDOM = valuDOM

    // }
    if (cmaPerSquareFt) {
      this.property.eccovals[this.eccovalIdx].cmapricePerSqFt = cmaPerSquareFt
    } else {
      if (!confirm("Warning: The comp data loaded has no sale price information. These properties will not affect the CMA and it will need to be input directly.\nProceed to saving these comps to the property?")) {
        console.log("NOT saving the comps to the property.")
        return loader.dismiss()
      }
    }

    /**
     * @note we may abandon this idea but for now
     */
    this.property.eccovals[this.eccovalIdx].overrideDOM = false // allow it to be set again

    this.calcAdjustedPrice(this.property, true)
    await this.save()
    console.log("--- AND THE PROPERTY IS ", this.property)

    loader.dismiss()
  }

  async viewProperties() {
    // alert("Viewing Properties for:\n1\n2\n3\n4")
    // const fmtAddr = cmp => `${cmp.address.streetAddress}, ${cmp.address.city}, ${cmp.address.state} ${cmp.address.zipcode}`
    // const fmtRow = cmp => `${fmtAddr(cmp)} - ${cmp.distance_crowFlies_miles.toFixed(2)} mi, sold ${cmp.daysSinceSale} days ago`
    // const usedComps = this.property.zillowComps.filter(c => c.in30PercThresh && c.daysSinceSale <= 90)
    // const rows = usedComps.map(fmtRow)
    // alert(`Comp Properties used in calculation:\n\n${rows.join('\n')}`)

    const modal = await this.modalController.create({
      component: CompsModal,
      componentProps: { property: this.property, isGuest: this.guestLink },
      cssClass: 'my-modal-class',
      // this is because i need the property udpated everytime :(
      backdropDismiss: false
    })

    modal.onDidDismiss().then(async ({ data }) => {
      console.log("WE DISMISSED!", data)
      if (data.updateOnClose) {
        console.log("Updating property!", data.property)
        this.property = data.property

        const compsSavedToast = await this.toastCtrl.create({
          message: 'Comps succcessfully saved!',
          duration: 3000,
          position: 'top'
        })

        if (!data.property.zillowFinalComps.cmaPerSqFt) {
          const ctl = await this.toastCtrl.create({
            message: 'No Usable CMA from comps. Keeping original value.',
            duration: 2000,
            position: 'top',
          })
          await ctl.present();
          ctl.onDidDismiss().then(() => {
            compsSavedToast.present()
          })
        } else {
          this.property.eccovals[this.eccovalIdx].cmapricePerSqFt = data.property.zillowFinalComps.cmaPerSqFt
          this.calcAdjustedPrice(data.property, true)
          compsSavedToast.present()
        }

        await this.save(false)

        // const { avgDOM, valuDOM } = await this.propertyService.getDOM(this.property._id) as any
        // this.property.eccovals[this.eccovalIdx].avgDOM = avgDOM
        // this.property.eccovals[this.eccovalIdx].valuDOM = valuDOM
        // await this.save(false)
      }
    })

    await modal.present()
  }


  newPdfTab(path) {
    let fname = 'VALUclick Report'
    if (this.property.search_formatted_address) fname += ` ${this.property.search_formatted_address.replace(', USA', '')}`
    fname += '.pdf'
    const link = document.createElement('a')
    link.href = path
    link.target = '_blank'
    link.textContent = "Click here to view Report"
    link.download = fname
    // document.body.appendChild(link)
    $('#generateReportCtr').append(link)
    link.click()
  }

  forceNewPdfTab(path) {
    const btn = document.createElement('button')
    btn.onclick = () => this.newPdfTab(path)
    btn.style.visibility = "hidden"
    // document.body.append(btn)
    $('#generateReportCtr').append(btn)
    setTimeout(() => {
        btn.click()
        btn.remove()
    }, 500)
  }

  generatePdf = async () => {
    console.log("-- GENERATING REPORT")
    const loader = await this.loadingController.create()
    loader.present()

    if (this.popover) {
      await this.popover.dismiss()
    }

    const res = await this.propertyService.generateReport(this.property._id)
    const blob = new Blob([res], { type: 'application/pdf' })
    console.log("Blob is: ", blob)
    
    // const fileUrl = URL.createObjectURL(blob)
    // window.open(fileUrl)
    // const 

    // solution A: allow the user to click for it
    // const href = URL.createObjectURL(blob)
    // const a = document.createElement('a')
    // a.href = href
    // a.target = "_blank"
    // a.textContent = "View Report in New Tab"
    // $('#generateReportCtr').append(a)
    // a.click()

    // const href = URL.createObjectURL(blob)
    // this.forceNewPdfTab(href)

    // Solution B: Just download and figure how to fix the "back" functionality.
    const href = URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = href
    let fname = 'VALUclick Report'
    if (this.property.search_formatted_address) fname += ` ${this.property.search_formatted_address.replace(', USA', '')}`
    fname += '.pdf'
    a.download = fname
    a.click()

    loader.dismiss()
  }

  /**
   * Get a corresponding modifier values guest valuation slider value
   * @param factor modifier factor that's being iterated through to layout the sliders
   * @returns 
   */
  getGuestValuationSliderValue(factor) {
    // console.log('--- factor = ', factor)
    const guestValuation = this.property.eccovals[1]
    const guestFactor = guestValuation.modifiers.find(f => f.title === factor.title)
    return guestFactor.value
  }

  get formattedActiveComps() {
    if (!this.completedOnInit) return []
    return this.property.zillowFinalComps.finalComps.map(c => {
      const compsData = mapCompsToGrid(c)
      return {
        ...compsData,
        pricePerSqFoot: `$${formatNumber(compsData.pricePerSqFoot, 'N/A')} / sqft`,
        price: `$${formatNumber(compsData.price, 'N/A')}`,
      }
    })
  }
}
